import React from 'react';

const Payments = () => {
    return (
        <div>
            <h1>Payments</h1>
            <p>This is the Payments component.</p>
        </div>
    );
};

export default Payments;