import React from 'react';
import DocumentFormat from '../GenericComponents/Layout/DocumentFormat';
import { DOC_STMT } from '../../Helpers/ConstantProperties';
import { getLocalDateString } from '../../Helpers/helpers';

const INRStatementDoc = ({ result }) => {
    const documentTitle = "Account Statement";

    const cData = {
        totalPages: 1,
        customerName: result.state.account.name,
        city: result.state.account.city,
        gstin: result.state.account.gstin,
        contactPhone: result.state.account.phone,
        contactEmail: result.state.account.email,
        statements: result.statements,
        runningTotal: result.runningTotal,
        runningText: result.runningText,
        textColor: result.textColor,
        toDate: result.state.toDate
    }

    const context = {
        showBank: (result.runningTotal > 0),
        document: DOC_STMT,
        documentTitle: documentTitle,
        cData: cData,
        detailsToShow: {
            "Statement Date": getLocalDateString(new Date()),
            "From Date": getLocalDateString(result.state.fromDate),
            "To Date": getLocalDateString(result.state.toDate)
        }
    }

    return <DocumentFormat context={context} />
};

export default INRStatementDoc;