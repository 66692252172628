import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useQueries } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { BALANCE, INR_RESOURCE_ID, TAX_INVOICE } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { ShowNumber } from '../../Helpers/helpers';

const PartyInvoiceAndBalance = ({ item }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const invoiceCount = {
        queryKey: [
            token,
            khID,
            TAX_INVOICE + item.id
        ],
        queryFn: async () => {
            return await serviceHelpers.getInvoices(token, khID, null, null, item.id, true);
        }
    }

    const inrBalance = {
        queryKey: [
            token,
            khID,
            INR_RESOURCE_ID,
            BALANCE + item.id
        ],
        queryFn: async () => {
            const balanceOption = {
                accountID: item.id,
                resourceID: INR_RESOURCE_ID,
                date: Date.now(),
            }

            return await serviceHelpers.getResourceBalance(token, khID, balanceOption);
        }
    }

    const results = useQueries({ queries: [inrBalance, invoiceCount] });

    if (results.every((result) => result.isSuccess)) {

        var balance = 0;

        if (results[0].data && results[0].data[0]) {
            balance = results[0].data[0].units;
        }

        return (
            <>
                <Grid2 xs={12} sm={3} md={2} lg={3}>
                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: { xs: 'left', sm: 'center' },
                            paddingLeft: { xs: '0.3rem' },
                            color: 'text.secondary'
                        }}
                    >
                        Invoices: {results[1].data[0] || 0}
                    </Typography>
                </Grid2>

                <Grid2 xs={12} sm={3} md={2} lg={3}>
                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: { xs: 'left', sm: 'center' },
                            paddingLeft: { xs: '0.3rem' },
                            color: 'text.secondary'
                        }}
                    >
                        Balance: {ShowNumber(balance, 2, true)}
                    </Typography>
                </Grid2>
            </>
        );
    }

    return <></>
};

export default PartyInvoiceAndBalance;