import { Box, Paper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import AddProperty from '../AddProperties/AddProperty';
import { INPUT_TYPE_NUM, PAY_MODE_CASH, propertyList, SchemaTypes } from '../../Helpers/ExtraProperties';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import PartyInvoiceDetails from './PartyInvoiceDetails';
import { PartyContext } from '../Party/PartyContext';
import { CASH_ACCOUNT_ID, INR_RESOURCE_ID, RECEIPT_SERIES_PREFIX, RECEIPT_VOUCHER, RECEIPTS, UPDATE_ON_RECEIPTS } from '../../Helpers/ConstantProperties';
import GenericMutateButton from '../GenericComponents/Buttons/GenericMutateButton';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

const NewReceiptForm = () => {
    const { party, setParty, sorterBatches } = useContext(PartyContext);
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [amount, setAmount] = useState(0);
    const [refID, setRefID] = useState("");
    const [notes, setNotes] = useState("");
    const [payMode, setPayMode] = useState(null);
    const [bank, setBank] = useState(null);

    const returnBatch = []

    const setBankAccount = (value) => {

        if (value?.id === CASH_ACCOUNT_ID) {
            setBank(value);
            setPayMode(PAY_MODE_CASH);
            setRefID(PAY_MODE_CASH);
        }
        else {
            setBank(value);
        }
    }

    if (amount > 0) {
        let required = Number(amount);
        const batchKeys = Object.keys(sorterBatches);

        for (const element of batchKeys) {
            if (required <= 0) break;

            const batchUnits = Math.abs(sorterBatches[element]);

            const alloted = Math.min(required, batchUnits);

            returnBatch.push({
                id: element,
                units: -alloted
            });

            required -= alloted;
        }

        if (required > 0) {
            returnBatch.push({
                id: "Advance",
                units: -required
            })
        }
    }

    const queryFn = async () => {
        // Create Note
        let note = "Paid to " + bank.name + " as " + payMode + " \r\n";
        if (notes !== "") note += notes

        // Create Voucher
        const voucher = {
            customerName: party.name,
            ammount: amount,
            mode: payMode,
            bank: bank.name,
            refranceId: refID,
            type: RECEIPT_VOUCHER,
            date: new Date().valueOf(),
            verified: true,
            transactions: [],
            notes: note,
            id: {
                autoIncrement: true,
                prefix: RECEIPT_SERIES_PREFIX
            }
        }

        // From Party
        voucher.transactions.push({
            accountID: party.id,
            resourceID: INR_RESOURCE_ID,
            units: -1 * amount,
            batches: returnBatch
        })

        // To Bank
        voucher.transactions.push({
            accountID: bank.id,
            resourceID: INR_RESOURCE_ID,
            units: amount,
            batches: [{ id: payMode, units: amount }]
        })

        return serviceHelpers.creteProductVoucher(token, khID, voucher);
    }

    const canSubmit = party !== null
        && amount !== 0
        && refID !== ""
        && payMode !== null
        && bank !== null

    const onSuccess = async () => {
        await queryClient.invalidateQueries({
            predicate: (query) =>
                query.queryKey.includes(UPDATE_ON_RECEIPTS)
        });

        navigate(RECEIPTS)
        setAmount(0);
        setBank(null);
        setPayMode(null);
        setRefID("");
    }

    return (
        <>
            <GenericFormHeader title={"Add Receipt"} enableBack={true} />
            <Paper elevation={1} sx={{ m: 1 }}>

                <Grid2 container rowGap={1} padding={1}>

                    <Grid2 xs={12} md={3} alignContent={"flex-end"}>
                        <AddProperty
                            data={{
                                item: {
                                    displayName: 'Receipt Date',
                                    name: 'receiptDate',
                                    required: true,
                                    type: SchemaTypes.DATEPICKER,
                                    helperText: 'Please Select the Receipt Date',
                                },
                                extraProps: {
                                    disableFuture: true
                                }
                            }}
                            deleteField={(element) => { setSelectedDate(dayjs()) }}
                            currentValue={selectedDate}
                            onChange={(e) => setSelectedDate(e.value)}
                        />
                    </Grid2>

                    <Grid2 xs={12} md={5}>

                        <AddProperty
                            data={{
                                item: {
                                    displayName: 'Receipt Party',
                                    name: 'receiptParty',
                                    required: true,
                                    type: SchemaTypes.PARTYDROPDOWN,
                                    helperText: 'Please Select the Party',
                                }
                            }}
                            deleteField={(element) => { setParty(null) }}
                            currentValue={party}
                            onChange={(e) => setParty(e.value)}
                        />

                    </Grid2>

                    <Grid2 xs={12} md={4}>

                        <AddProperty
                            data={{
                                item: {
                                    displayName: "Amount",
                                    name: "amount",
                                    type: SchemaTypes.Number,
                                    required: true,
                                    helperText: 'Please enter the Receipt Amount',
                                },
                                inputMode: INPUT_TYPE_NUM
                            }}
                            deleteField={(element) => { setAmount(0) }}
                            currentValue={amount}
                            onChange={(e) => setAmount(e.value)}
                        />

                    </Grid2>

                    <Grid2 xs={12}>

                        <Box display={"block"}>

                            <Grid2
                                container
                                display={"flex"}
                                flexDirection={{ xs: "column-reverse", md: "initial" }}>

                                <Grid2 xs={12} md={8}>
                                    <Box display={"block"}>
                                        <Grid2 container rowGap={1}>
                                            <Grid2 xs={12} md={5}>

                                                <AddProperty
                                                    data={{
                                                        item: {
                                                            displayName: 'Select Account ',
                                                            name: 'payaccount',
                                                            required: true,
                                                            type: SchemaTypes.PAYACCOUNTDROPDOWN,
                                                            helperText: 'Please Select the Recipt Account',
                                                        }
                                                    }}
                                                    deleteField={
                                                        (element) => { setBankAccount(null) }}
                                                    currentValue={bank}
                                                    onChange={(e) => setBankAccount(e.value)}
                                                />

                                            </Grid2>

                                            <Grid2 xs={12} md={3}>

                                                <AddProperty
                                                    data={{
                                                        item: propertyList.paymentMode
                                                    }}
                                                    deleteField={(element) => { setPayMode(null) }}
                                                    currentValue={payMode}
                                                    onChange={(e) => setPayMode(e.value)}
                                                />

                                            </Grid2>

                                            <Grid2 xs={12} md={4}>

                                                <AddProperty
                                                    data={{
                                                        item: {
                                                            displayName: "Referance ID",
                                                            name: "refID",
                                                            type: SchemaTypes.String,
                                                            required: true,
                                                            helperText: 'Please enter the Referance ID',
                                                        },
                                                    }}
                                                    deleteField={(element) => { setRefID("") }}
                                                    currentValue={refID}
                                                    onChange={(e) => setRefID(e.value)}
                                                />

                                            </Grid2>

                                            <Grid2 xs={12}>

                                                <AddProperty
                                                    data={{
                                                        item: {
                                                            displayName: "Notes",
                                                            name: "notes",
                                                            type: SchemaTypes.String,
                                                            required: true,
                                                            helperText: 'Please enter Notes',
                                                        },
                                                        extraProps: {
                                                            multiline: true,
                                                            rows: 2
                                                        }
                                                    }}
                                                    deleteField={(element) => { setNotes("") }}
                                                    currentValue={notes}
                                                    onChange={(e) => setNotes(e.value)}
                                                />

                                            </Grid2>
                                        </Grid2>
                                    </Box>
                                </Grid2>

                                <Grid2 xs={12} md={4} padding={2}>
                                    <PartyInvoiceDetails batches={returnBatch} />
                                </Grid2>
                            </Grid2>
                        </Box>
                    </Grid2>

                    <Grid2 xs={12} textAlign={"center"} paddingY={2}>
                        <GenericMutateButton
                            queryFn={queryFn}
                            disable={!canSubmit}
                            onSuccess={onSuccess}
                            successMessage="Receipt Created" />
                    </Grid2>

                </Grid2>

            </Paper>
        </>
    );
};

export default NewReceiptForm;