import Grid2 from '@mui/material/Unstable_Grid2';
import { ShowNumber } from '../../Helpers/helpers';
import { Alert } from '@mui/material';
import React from 'react';

const PartyInvoiceDetails = ({ batches }) => {
    if (batches.length > 0) {
        return (
            <Grid2 container>
                {
                    batches.map((batch) => {

                        return (
                            <React.Fragment key={batch.id}>
                                <Grid2 xs={6} textAlign={"left"}>
                                    {batch.id}
                                </Grid2>
                                <Grid2 xs={6} textAlign={"right"}>
                                    {ShowNumber(Math.abs(batch.units), 2, true)}
                                </Grid2>
                            </React.Fragment>
                        )
                    })
                }

            </Grid2>
        )
    }

    return <Alert severity='info'>No Inovices</Alert>
};

export default PartyInvoiceDetails;