import { Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import GenericFormHeader from '../GenericComponents/FormComponent/GenericFormHeader';
import AddProperty from '../AddProperties/AddProperty';
import { propertyList, SchemaTypes } from '../../Helpers/ExtraProperties';
import dayjs from 'dayjs';
import { ACCOUNTSTATMENT, INR_RESOURCE_ID } from '../../Helpers/ConstantProperties';
import { useNavigate } from 'react-router-dom';

const GetStatement = () => {
    const lastTwoWeeks = dayjs().add(-15, 'day');

    const [fromDate, setFromDate] = useState(lastTwoWeeks);
    const [toDate, setToDate] = useState(dayjs());
    const [account, setAccount] = useState(null);

    const navigateTo = useNavigate();

    const canSubmit = account !== null
        && fromDate <= toDate;

    const onSubmit = () => {
        const state = {
            account: account,
            resource: { id: INR_RESOURCE_ID },
            fromDate: fromDate.toDate(),
            toDate: toDate.toDate(),
        }

        navigateTo(ACCOUNTSTATMENT, { state: state })
    }

    return (
        <>
            <GenericFormHeader title={"INR Statement"} enableBack={true} />

            <Grid2 container padding={2}>
                <Grid2 xs={12} md={6}>
                    <AddProperty
                        data={{ item: propertyList.accountDropDown }}
                        currentValue={account}
                        deleteField={(e) => setAccount(null)}
                        onChange={(e) => setAccount(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: 'From Date',
                                name: 'fromDate',
                                required: true,
                                type: SchemaTypes.DATEPICKER,
                                helperText: 'Please Select the From Date',
                            },
                            extraProps: {
                                maxDate: toDate
                            }
                        }}
                        deleteField={(element) => { setFromDate(lastTwoWeeks) }}
                        currentValue={fromDate}
                        onChange={(e) => setFromDate(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12} md={3}>
                    <AddProperty
                        data={{
                            item: {
                                displayName: 'To Date',
                                name: 'toDate',
                                required: true,
                                type: SchemaTypes.DATEPICKER,
                                helperText: 'Please Select the To Date',
                            },
                            extraProps: {
                                minDate: fromDate
                            }
                        }}
                        deleteField={(element) => { setToDate(dayjs()) }}
                        currentValue={toDate}
                        onChange={(e) => setToDate(e.value)}
                    />
                </Grid2>

                <Grid2 xs={12} textAlign={"center"} paddingTop={2}>
                    <Button disabled={!canSubmit} onClick={onSubmit}>Get Statement</Button>
                </Grid2>
            </Grid2>
        </>
    )
};

export default GetStatement;