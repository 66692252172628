import { CGST_BATCH_ID, CODE_IP, COLOR_RED, COMMON_BATCH, EIGHTEEN_GST, GREEN_BUTTON, IGST_BATCH_ID, INQUIRY_STORE_ACCOUNT_ID, INR_RESOURCE_ID, INTERNAL_PROCESS, LABOUR_COST, OUT_GST_ACCOUNT_ID, OUT_GST_B2C_CUSTOMER, PRIMARY_COLOR, ROUNDOFF_ID, SALE_BATCH_STORE_SUFFIX, SGST_BATCH_ID } from "../../Helpers/ConstantProperties";
import { NO_DATA } from "../../Helpers/ExtraProperties";
import { checkValue, getRoundUptoTwoPlaces, getSaleRate, getTotalUnitsForReq, isIGST, ShowNumber } from "../../Helpers/helpers";

const getIDFromData = (data) => data.productHSNcode + "-" + data.GSTRate;

export const getRateDetails = ({
    inquiry,
    currentFirm,
    docFormat,
    isBOM = false,
}) => {

    const igstApp = isIGST(currentFirm, inquiry);

    const retVal = {
        igstApp: igstApp,
        items: [],
        totalQuantity: 0,
        totalTaxableAmount: 0,
        totalTax: 0,
        totalAmountAfterTax: 0,
        taxTypeWiseList: [],
        hsnWiseList: {},
        roundedTotal: 0,
        roundOff: 0,
        totalPages: 1,
    }

    // Save few of the inquiry properties to the return object
    // This will enable us to show the document without inquiry
    // object present.
    retVal.poNumber = inquiry.poNumber;
    retVal.customerName = inquiry.customerName;
    retVal.contactPerson = inquiry.contactPerson;
    retVal.city = inquiry.city;
    retVal.gstin = inquiry.gstin
    retVal.panNumber = inquiry.panNumber
    retVal.contactPhone = inquiry.contactPhone
    retVal.contactEmail = inquiry.contactEmail

    retVal.paymentTerms = inquiry.paymentTerms
    retVal.transportTerms = inquiry.transportTerms
    retVal.otherTnC = inquiry.otherTnC

    const calculateTaxData = (itemToAdd) => {
        itemToAdd.tax = itemToAdd.taxableAmount * itemToAdd.GSTRate / 100;

        // Make the tax even this is because we spilt the tax in 2
        // in case of CGST, and due to rounding error, the total
        // doesnt match.
        itemToAdd.tax = getRoundUptoTwoPlaces(itemToAdd.tax / 2);
        itemToAdd.tax = itemToAdd.tax * 2;

        itemToAdd.totalAmount = itemToAdd.taxableAmount + itemToAdd.tax;

        itemToAdd.gstString = igstApp
            ? itemToAdd.GSTRate + "%"
            : (itemToAdd.GSTRate / 2) + "% - " + (itemToAdd.GSTRate / 2) + "%"

        itemToAdd.gstValueString = igstApp
            ? "IGST: " + ShowNumber(itemToAdd.tax, 2, true)
            : "C/S GST: " + ShowNumber(itemToAdd.tax / 2, 2, true)

        return itemToAdd;
    }

    const getItemWithDetails = (itemToAdd, product, fgQty = 1) => {
        // Sale Rate and Inclusive Rate needs to be rounded.
        itemToAdd.saleRate = getRoundUptoTwoPlaces(parseFloat(getSaleRate(product)));

        itemToAdd.inclusiveRate = itemToAdd.saleRate * (100 + itemToAdd.GSTRate) / 100;
        itemToAdd.inclusiveRate = getRoundUptoTwoPlaces(itemToAdd.inclusiveRate);

        itemToAdd.units = getTotalUnitsForReq(product, fgQty)
        itemToAdd.productdescription = product.productdescription;

        itemToAdd.taxableAmount = itemToAdd.saleRate * itemToAdd.units;

        return itemToAdd;
    }

    inquiry.products?.forEach((product) => {
        let itemToAdd = getItemWithDetails({ ...product.product }, product);

        if (isBOM) {
            itemToAdd.taxableAmount = 0;
            itemToAdd.units = 0;

            const requirements = [...(product.rmlist || []), ...(product.processes || [])];

            var labour = {
                fgCode: itemToAdd.productItemcode,
                id: CODE_IP,
                productItemcode: CODE_IP,
                name: LABOUR_COST,
                productHSNcode: NO_DATA,
                units: 1,
                saleRate: 0,
                taxableAmount: 0,
                GSTRate: EIGHTEEN_GST
            }

            requirements.forEach((req) => {
                const reqToAdd = getItemWithDetails({ ...req.product }, req, product.units);
                reqToAdd.fgCode = itemToAdd.productItemcode;

                itemToAdd.taxableAmount += reqToAdd.taxableAmount;

                if (reqToAdd.productState === INTERNAL_PROCESS) {
                    labour.saleRate += reqToAdd.taxableAmount;
                    labour.taxableAmount += reqToAdd.taxableAmount;
                }
                else {
                    itemToAdd.units += reqToAdd.units;
                    retVal.items.push(reqToAdd);
                }
            })

            if (labour.taxableAmount > 0) {
                itemToAdd.units += labour.units;
                retVal.items.push(labour);
            }

            itemToAdd = calculateTaxData(itemToAdd)
        }
        else {
            retVal.items.push(calculateTaxData(itemToAdd));
        }

        const gstID = getIDFromData(itemToAdd);

        if (!retVal.hsnWiseList[gstID]) {
            retVal.hsnWiseList[gstID] = {
                taxable: 0,
                hsncode: itemToAdd.productHSNcode,
                GSTRate: itemToAdd.GSTRate,
                quantity: 0,
                tax: 0,
            };
        }

        retVal.hsnWiseList[gstID].taxable += itemToAdd.taxableAmount;
        retVal.hsnWiseList[gstID].quantity += itemToAdd.units;
        retVal.hsnWiseList[gstID].tax += itemToAdd.tax;

        retVal.totalQuantity += itemToAdd.units;
        retVal.totalTaxableAmount += itemToAdd.taxableAmount;
        retVal.totalTax += itemToAdd.tax;
        retVal.totalAmountAfterTax += itemToAdd.totalAmount;
    })

    // For IGST -- Total Taxable is all IGST
    if (igstApp) {
        retVal.taxTypeWiseList.push({
            type: IGST_BATCH_ID,
            taxable: retVal.totalTaxableAmount,
            tax: retVal.totalTax
        })
    }
    else {
        // For CGST and SGST the total tax is half of total
        // and taxable is same as total
        retVal.taxTypeWiseList.push({
            type: CGST_BATCH_ID,
            taxable: retVal.totalTaxableAmount,
            tax: retVal.totalTax / 2
        })

        retVal.taxTypeWiseList.push({
            type: SGST_BATCH_ID,
            taxable: retVal.totalTaxableAmount,
            tax: retVal.totalTax / 2
        })
    }

    retVal.roundedTotal = Math.round(retVal.totalAmountAfterTax)
    retVal.roundOff = getRoundUptoTwoPlaces(retVal.roundedTotal - retVal.totalAmountAfterTax)

    if (docFormat?.maxInPage) {
        let totalItems = retVal.items.length;
        const maxInPage = docFormat.maxInPage;
        const pageSize = docFormat.pageSize;

        if (totalItems > maxInPage) {
            const rem = totalItems - maxInPage;
            retVal.totalPages += Math.ceil(rem / pageSize)
        }
    }

    return retVal;
}

export const getSaleINRTransactions = (inquiry, cData, invoiceID) => {
    const retVal = [];

    // Store transaction
    const storeTxn = {
        units: 0,
        batches: [],
        accountID: INQUIRY_STORE_ACCOUNT_ID,
        resourceID: INR_RESOURCE_ID,
    }

    cData.items.forEach((product) => {
        const suffix = SALE_BATCH_STORE_SUFFIX;

        storeTxn.units -= product.taxableAmount;
        storeTxn.batches.push({
            id: suffix + product.id,
            units: -product.taxableAmount
        })
    })


    // Tax transaction
    const taxTxn = {
        units: -cData.totalTax,
        batches: [],
        accountID: OUT_GST_ACCOUNT_ID,
        hsnWiseList: cData.hsnWiseList,
        taxable: cData.totalTaxableAmount,
        resourceID: INR_RESOURCE_ID,
        gstin: inquiry.gstin || OUT_GST_B2C_CUSTOMER,
        partyName: checkValue(inquiry.customerName),
    }

    cData.taxTypeWiseList.forEach((tax) => {
        taxTxn.batches.push({
            id: tax.type,
            units: -tax.tax
        })
    })

    // Customer transaction
    const customerTxn = {
        accountID: inquiry.customerId,
        resourceID: INR_RESOURCE_ID,
        batches: [{ id: invoiceID, units: cData.roundedTotal }],
        units: cData.roundedTotal
    }

    retVal.push(storeTxn, taxTxn, customerTxn);

    // Round-Off

    // As the RoundOff value represents the differance to be added to
    // the products and tax so to make the total rounded to zero
    // we need to treat the round-off value same as other 2 values
    // as we are negating the other two values, we need to negate this
    // as well.
    if (cData.roundOff !== 0) {
        const roundTxn = {
            accountID: ROUNDOFF_ID,
            resourceID: INR_RESOURCE_ID,
            units: -cData.roundOff,
            batches: [{ id: COMMON_BATCH, units: -cData.roundOff }]
        }

        retVal.push(roundTxn);
    }

    return retVal;
}

const getCrDr = (number) => number > 0 ? " CR" : " DR";

const getColor = (number) => {
    if (number > 0) return COLOR_RED;
    if (number < 0) return GREEN_BUTTON;
    return PRIMARY_COLOR
}

export const getStatementData = ({ transactions }) => {
    var runningTotal = 0;
    const statements = []

    transactions.forEach((txn) => {
        const txnRunning = runningTotal + txn.units;
        const crDr = getCrDr(txnRunning)

        const statement = {
            ...txn,
            running: txnRunning,
            runningText: ShowNumber(Math.abs(txnRunning), 2, true) + crDr,
            textColor: getColor(txnRunning)
        }

        if (txn.units >= 0) {
            statement.debit = 0;
            statement.credit = Math.abs(txn.units)
        }
        else {
            statement.credit = 0;
            statement.debit = Math.abs(txn.units)
        }

        if (txn.opening) {
            statement.vid = "--";
            statement.type = "Opening Balance";
            statement.refranceId = "";
            statement.notes = ""
        }

        runningTotal += txn.units;
        statements.push(statement)
    })

    const crDr = getCrDr(runningTotal)
    const runningText = ShowNumber(Math.abs(runningTotal), 2, true) + crDr;
    const textColor = getColor(runningTotal);

    return { statements, runningTotal, runningText, textColor }
}