import { Image, View } from '@react-pdf/renderer';

const StmtTxnArrow = ({ txn }) => {
    var path = ".\\zero.png";

    if (txn.credit > 0) path = ".\\credit.png"
    if (txn.debit > 0) path = ".\\debit.png"

    return (
        <View>
            <Image
                src={path}
                alt={"alt"}
                style={{ height: '5mm', objectFit: 'contain' }}
            />
        </View>
    );
};

export default StmtTxnArrow;