import { checkInquiryStatusAndProductType } from "./helpers";

export const updateRequiredPropsForOrder = (updateObject, inquiry) => {
    // If there are any custom products then mark in Design
    // Else move it to store.
    const details = checkInquiryStatusAndProductType(inquiry);

    if (details.hasCustomProduct) {
        updateObject.designNeeded = true;
    }
    else {
        updateObject.storeNeeded = true;
    }
}

export const showRateChangeForInvoice = (disableRate) => {
    return false
}