import { PartyProvider } from "../Party/PartyContext";
import NewReceiptForm from "./NewReceiptForm";

const NewReceipt = () => {
    return (
        <PartyProvider>
            <NewReceiptForm />
        </PartyProvider>
    )
};

export default NewReceipt;