import React, { useContext } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { getProductResultDetails, getStoreProductQueryObject } from './InquiryCalculations';
import { useQueries } from '@tanstack/react-query';
import GenericSpinner from '../GenericComponents/FormComponent/GenericSpinner';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import InquiryDispatchCard from './inquiryDispatchCard';

const InquiryDispatch = ({ inquiry }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queries = [];

    inquiry.products.forEach((product) => {
        queries.push(getStoreProductQueryObject(token, khID, product.product.id));
    })

    const results = useQueries({ queries: queries });

    if (results.some((result) => result.isPending)) return <GenericSpinner />

    // TODO: Need Better Error Handeling
    if (results.some((result) => result.isError)) return <GenericErrorComponent />

    if (results.every((result) => result.isSuccess)) {
        const balanceDetails = getProductResultDetails(
            inquiry,
            null,
            results);

        return <InquiryDispatchCard balanceDetails={balanceDetails} inquiry={inquiry} />
    }

    return <></>
};

export default InquiryDispatch;