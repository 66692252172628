import { PAY_ACCOUNTS, UPDATE_ON_ACCOUNT } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { payAccountList } from '../../Helpers/ExtraProperties';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { GenericVerifyComponent } from '../GenericComponents/VerifyComponent/FirmVerifyComponent';
import GenericForm from '../GenericComponents/FormComponent/GenericForm';

const AddNewPayAccount = () => {
    const successMessage = "Payment Account Added SuccessFully "

    const queryFunction = async (state, token, khID) => {
        return await serviceHelpers.markPayAccount(token, khID, state.account.id)
    }

    const invalidateQueryKey = UPDATE_ON_ACCOUNT;

    const newFormProps = {
        formTitle: "Add New Payment Account",
        propertyList: payAccountList,
        queryFunction: queryFunction,
        queryKeyValue: invalidateQueryKey,
        navigateTo: PAY_ACCOUNTS,
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={successMessage} />,
        addButtonText: "Add",
        enableVerify: true,
        VerifyAlertComponent: GenericVerifyComponent,
        buttonClasses: "",
        currentData: {},
        enableClear: true
    }

    return (
        <GenericForm
            {...newFormProps}
        />
    )
};

export default AddNewPayAccount;