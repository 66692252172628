export const statementLayout = [
    [
        {
            label: "Logo",
            layout: {
                xs: 2
            },
        },
        {
            label: "Heading",
            layout: {
                xs: 10,
            },
        },
    ],
    [
        {
            label: "Divider",
            style: {
                paddingVertical: "3mm",
            }
        }
    ],
    [
        {
            label: "From",
        },
        {
            label: "To",
        },
        {
            label: "Details",
        },
    ],
    [
        {
            label: "Transactions",
            style: {
                paddingVertical: "3mm",
            }
        },
    ],
    [
        {
            showOnLast: true,
            label: "StatementBank",
            layout: {
                xs: 5
            },
            style: {
                paddingVertical: "3mm",
                fontSize: 8,
            }
        },
        {
            showOnLast: true,
            label: "Empty",
            layout: {
                xs: 3
            },
        },
        {
            showOnLast: true,
            label: "StatementTotal",
            layout: {
                xs: 4
            },
            style: {
                paddingRight: "3mm",
                paddingBottom: "3mm",
                alignSelf: "flex-end"
            }
        }
    ],
    [
        {
            showOnLast: true,
            label: "EndDivider",
            style: {
                paddingVertical: "3mm",
            }
        }
    ],
    [
        {
            showOnLast: true,
            label: "EndOfStatement",
        }
    ]
]