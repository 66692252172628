import React, { createContext, useContext, useState } from 'react';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { BALANCE, INR_RESOURCE_ID } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { useQuery } from '@tanstack/react-query';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';

const PartyContext = createContext();

const PartyProvider = ({ children }) => {
    const [party, setParty] = useState(null);

    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    let balanceObject = {
        units: 0,
        batches: []
    }

    let sorterBatches = []

    const queryFn = async () => {
        if (!party) return [];

        const balanceOption = {
            accountID: party.id,
            resourceID: INR_RESOURCE_ID,
            date: Date.now(),
        }

        return await serviceHelpers.getResourceBalance(token, khID, balanceOption)
    }

    const queryKey = [
        token,
        khID,
        party?.id,
        BALANCE + INR_RESOURCE_ID
    ]

    const { data, error } = useQuery({
        queryKey: queryKey,
        queryFn: queryFn,
        enabled: (party !== null)
    });

    if (error) return <GenericErrorComponent error={error} />

    if (data && data[0]) {
        balanceObject = data[0];
        const balanceBatch = data[0].batches;

        sorterBatches =
            Object.keys(balanceBatch)
                .sort()
                .reduce((acc, key) => {
                    acc[key] = balanceBatch[key];
                    return acc;
                }, {});
    }

    return (
        <PartyContext.Provider value={{
            party: party,
            setParty: setParty,
            balance: balanceObject,
            sorterBatches: sorterBatches
        }}>

            {children}
        </PartyContext.Provider>
    )
};

export { PartyContext, PartyProvider };