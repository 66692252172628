import {
    Khatavani,
    Accounts,
    Balance,
    Files,
    ResourceGroups,
    Resources,
    Transactions,
    UserRoles,
    Users,
    Vouchers,
    UploadData,
    Batch,
    Serialized,
    Messages,
    WAID,
    PAYMENT
} from "khatavani-client";
import { BANK_ACCOUNT_TAG, CLOSED_INQUIRY_TAG, CONTACT, INQUIRY_TAG, INWORD_VOUCHER_TYPE, PARTY_TAG, PRODUCT_TAG } from "./ExtraProperties";
import { STATE_UPDATE_RESOURCE_ID, CLOSED, COMMON_BATCH, CUSTOMER, INPROGRESS_INQUIRY_RESOURCE_ID, INQUIRY, INQUIRY_SOURCE, INQUIRY_VOUCHER_TYPE, OPEN, VENDOR, AccountsToCreate, DELETE_FIELD, CONVERTED, DISCOUNT_SLAB_TAG, INQUIRY_STORE_ACCOUNT_ID, RMLIST, userLevels, STORE_MANAGER_USER_LEVEL_ID, PRODUCTION_MANAGER_USER_LEVEL_ID, QUOTATION_READY, QUOTATION_SERIES_PREFIX, ResourcesToCreate, PRODUCT_TYPE_RAW, PRODUCT_TYPE_PROCESS, PROCESSESS, REFERANCE_ID, REFERANCE, PRODUCT_TYPE_CUSTOM, NUMBERS, PRODUCT_TYPE_FINISHED, QC_STORE_ACCOUNT_ID, STORE_VOUCHER, IGNORED_ID, QC_MANAGER_USER_LEVEL_ID, TAX_INVOICE, LABOURS, INR_RESOURCE_ID, LABOUR_VOUCHER_TYPE, EXTERNAL_VOUCHER_TYPE, RECEIPT_VOUCHER, ACCOUNT_USER_LEVEL_ID } from "./ConstantProperties";
import { CONTENT_TYPE, StoreFileToSession, addBalancePerInquiryProduct, addDaysToToday, createServerErrorMsg, differentiateArrays, fileMetaDataObject, getDistibutedBatches, getProductFiltered, getProductIDResourceID, getProductTransactionArray, getProductVoucher, getRequirementVoucherObject, getResourceObject, inwordIdWithDate, returnUniqueResource, returnUniqueResourceProcess } from "./helpers";
import { v4 as uuidv4 } from 'uuid';
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { updateRequiredPropsForOrder } from "./customHelper";

const serviceEndpoints = {
    KhatavaniEndpoint: Khatavani(),

    UsersEndpoint: Users(),

    ResourcesEndpoint: Resources(),

    AccountsEndpoint: Accounts(),

    VouchersEndpoint: Vouchers(),

    TransactionsEndpoint: Transactions(),

    ResourceGroupsEndpoint: ResourceGroups(),

    UserRolesEndpoint: UserRoles(),

    BalanceEndpoint: Balance(),

    FileEndpoint: Files(),

    BatchEndpoint: Batch(),

    SerializedEndpoint: Serialized(),

    MessageEndpoint: Messages(),

    WAIDEndpoint: WAID(),

    PaymentEndpoint: PAYMENT(),
}

export class serverMethods {

    async getWaID(token, khID) {
        return await serviceEndpoints.WAIDEndpoint.get(token, khID);
    }

    async getUsers(token, khID, body, options) {
        return await serviceEndpoints.UsersEndpoint.get(token, khID, body, options)
    }

    async createUser(token, khID, body) {
        if (isValidPhoneNumber(body.phoneNumber || '')) body.phoneNumber = parsePhoneNumber(body.phoneNumber).formatInternational()
        return await serviceEndpoints.UsersEndpoint.create(token, khID, body)
    }

    async updateUser(token, khID, body, id) {
        return await serviceEndpoints.UsersEndpoint.patch(token, khID, body, id)
    }

    async deleteUser(token, khID, id) {
        return await serviceEndpoints.UsersEndpoint.delete(token, khID, id)
    }

    async getFirm(token) {
        return await serviceEndpoints.KhatavaniEndpoint.get(token)
    }

    async createFirm(token, firmObject, file = []) {
        let accounts = [...AccountsToCreate];

        // firmObject.resources = INQUIRY_STATUS_RESOURCES;
        if (firmObject.accounts) accounts.push(...firmObject.accounts)
        firmObject.accounts = accounts;
        firmObject.userLevels = userLevels;
        firmObject.resources = ResourcesToCreate

        var result = await serviceEndpoints.KhatavaniEndpoint.create(token, firmObject.khID, firmObject)
        var fileStore = [];

        file.forEach(element => {
            let fileObject = fileMetaDataObject(element.name, element.value.Store, firmObject.khID);
            fileStore.push(fileObject);
        })

        await UploadData(fileStore, token, result.id, null, true);

        return result
    }

    async editFirm(token, firmObject, khID) {
        return await serviceEndpoints.KhatavaniEndpoint.put(token, null, firmObject, khID);
    }

    async inquiryRefranceVoucher(token, khID, inquiryID, inquirySourceId, customerId) {
        const VoucherType = INQUIRY_VOUCHER_TYPE;
        const resourceID = INPROGRESS_INQUIRY_RESOURCE_ID;
        var transactions = [{
            accountID: inquiryID,
            resourceID: resourceID,
            batches: [{ id: COMMON_BATCH, units: -1 }],
            units: -1
        }];

        if (customerId && inquirySourceId) {
            transactions = [{
                accountID: inquiryID,
                resourceID: resourceID,
                batches: [{ id: COMMON_BATCH, units: -2 }],
                units: -2
            }]
        }

        if (customerId) {
            transactions.push({
                accountID: customerId,
                resourceID: resourceID,
                batches: [{ id: inquiryID, units: +1 }],
                units: +1
            })
        }

        if (inquirySourceId) {
            transactions.push({
                accountID: inquirySourceId,
                resourceID: resourceID,
                batches: [{ id: inquiryID, units: +1 }],
                units: +1
            })
        }

        const voucherObject = {
            date: new Date().valueOf(),
            type: VoucherType,
            transactions: transactions
        }

        return await serviceEndpoints.VouchersEndpoint.create(token, khID, voucherObject);
    }

    async getAllAccounts(token, khID, pageParam) {
        const inrOption = {
            inrCapable: true
        }

        return await serviceEndpoints.AccountsEndpoint.get(token, khID, null, inrOption, pageParam);
    }

    async updateAccount(token, khID, body, accountId) {
        return await serviceEndpoints.AccountsEndpoint.put(token, khID, body, accountId);
    }

    async getPaymentAccounts(token, khID, pageParam) {
        const option = {
            enablePayment: true
        }

        return await serviceEndpoints.AccountsEndpoint.get(token, khID, null, option, pageParam);
    }

    async deletePaymentAccount(token, khID, accountID) {
        const update = {
            enablePayment: DELETE_FIELD
        }

        return await serviceEndpoints.AccountsEndpoint.patch(token, khID, update, accountID);
    }

    async markPayAccount(token, khID, accountID) {
        const update = {
            enablePayment: true
        }

        return await serviceEndpoints.AccountsEndpoint.patch(token, khID, update, accountID);
    }

    async createParty(token, khID, partyObject) {
        partyObject.tag = PARTY_TAG;
        partyObject.inrCapable = true
        partyObject.effectAccess = [STORE_MANAGER_USER_LEVEL_ID, PRODUCTION_MANAGER_USER_LEVEL_ID];
        return await serviceEndpoints.AccountsEndpoint.create(token, khID, partyObject)
    }

    async updateParty(token, khID, updatedObject, id) {
        return await serviceEndpoints.AccountsEndpoint.put(
            token,
            khID,
            updatedObject,
            id
        )
    }

    async deleteParty(token, khID, id) {
        return await serviceEndpoints.AccountsEndpoint.delete(token, khID, id);
    }

    async getUserRoles(token, khID, body, options, pageParam) {
        return await serviceEndpoints.UserRolesEndpoint.get(token, khID, body, options, pageParam);
    }

    async createUserRole(token, khID, userRoleObject) {
        return await serviceEndpoints.UserRolesEndpoint.create(token, khID, userRoleObject)
    }

    async updateUserRole(token, khID, state, id) {
        return serviceEndpoints.UserRolesEndpoint.put(token, khID, state, id)
    }

    async deleteUserRole(token, khID, id) {
        return await serviceEndpoints.UserRolesEndpoint.delete(token, khID, id);
    }

    async getParties(token, khID, body, options, pageParam) {
        const partyOptions = options ? options : {}
        partyOptions.tag = PARTY_TAG;
        return await serviceEndpoints.AccountsEndpoint.get(token, khID, body, partyOptions, pageParam);
    }

    async createCustomProducts(token, khID, Products) {
        return await Promise.all(
            Products?.map(async (product) => {
                if (!product.product.type) {
                    const newProduct = {
                        id: product.product.id,
                        name: product.product.name,
                        type: PRODUCT_TYPE_CUSTOM,
                        unit: NUMBERS,
                        tag: PRODUCT_TAG
                    }
                    await this.createProduct(token, khID, newProduct);
                    product.product = newProduct;
                }
                return product;
            })
        );
    }

    async createProduct(token, khID, body) {
        body.tag = PRODUCT_TAG
        body.entityPublicAccess = true;
        body.effectAccess = [STORE_MANAGER_USER_LEVEL_ID, PRODUCTION_MANAGER_USER_LEVEL_ID, QC_MANAGER_USER_LEVEL_ID]
        return await serviceEndpoints.ResourcesEndpoint.create(
            token,
            khID,
            body);
    }

    async getProducts(token, khID, body, options, pageParam) {
        const partOptions = options ? options : {}
        partOptions.tag = PRODUCT_TAG;
        return await serviceEndpoints.ResourcesEndpoint.get(token, khID, body, partOptions, pageParam);
    }

    async getAllProducts(token, khID, body, options) {
        const partOptions = options ? options : {}
        partOptions.tag = PRODUCT_TAG;
        return await serviceEndpoints.ResourcesEndpoint.getAll(token, khID, body, partOptions);
    }

    async inwordProductToStore(token, khID, state, products) {
        const today = new Date();

        const batches = []
        const transaction = []

        products.forEach((product, index) => {
            batches.push({
                id: inwordIdWithDate(index),
                name: state.vendorDropDown.name,
                rate: product.rate,
                entityPublicAccess: true
            })

            transaction.push({
                accountID: state.vendorDropDown.id,
                resourceID: product.product.id,
                units: product.units * -1,
                rate: product.rate,
                batches: [{ id: state.refid, units: product.units * -1 }]
            })

            transaction.push({
                accountID: INQUIRY_STORE_ACCOUNT_ID,
                resourceID: product.product.id,
                units: product.units,
                batches: [{ index: index, units: product.units }]
            })
        })

        const voucher = {
            date: today.getTime(),
            verified: true,
            refranceId: state.refid,
            type: INWORD_VOUCHER_TYPE,
            batches: batches,
            transactions: transaction
        }

        return await serviceEndpoints.VouchersEndpoint.create(token, khID, voucher);
    }

    async getAllLeads(token, khID, body, options, withBalance = false, productType) {
        let data = await serviceEndpoints.AccountsEndpoint.getAll(token, khID, body, options);
        if (withBalance) {
            return await this.getBalanceWithResource(token, khID, data, productType)
        }
        return data;
    }

    async getOneLead(token, khID, id) {
        return await serviceEndpoints.AccountsEndpoint.getOne(token, khID, id);
    }

    async deleteProducts(token, khID, id) {
        return await serviceEndpoints.ResourcesEndpoint.delete(token, khID, id);
    }

    async updateProduct(token, khID, state, id) {
        return serviceEndpoints.ResourcesEndpoint.put(token, khID, state, id)
    }

    async getCustomers(token, khID, body, options, pageParam) {
        const customerOptions = options ? options : {}
        customerOptions.tag = PARTY_TAG;
        customerOptions.type = CUSTOMER;
        return await serviceEndpoints.AccountsEndpoint.get(token, khID, body, customerOptions, pageParam);
    }

    async getAllCustomers(token, khID, body, options) {
        const customerOptions = options ? options : {}
        customerOptions.tag = PARTY_TAG;
        customerOptions.type = CUSTOMER;
        return await serviceEndpoints.AccountsEndpoint.getAll(token, khID, body, customerOptions);
    }

    async getVendors(token, khID, body, options, pageParam) {
        const customerOptions = options ? options : {}
        customerOptions.tag = PARTY_TAG;
        customerOptions.type = VENDOR;
        return await serviceEndpoints.AccountsEndpoint.get(token, khID, body, customerOptions, pageParam);
    }

    async getLabours(token, khID, body, options, pageParam) {
        const customerOptions = options ? options : {}
        customerOptions.tag = PARTY_TAG;
        customerOptions.type = LABOURS;
        return await serviceEndpoints.AccountsEndpoint.get(token, khID, body, customerOptions, pageParam);
    }

    //Inquiry
    async createInquiry(token, khID, InquiryObject, onDate = new Date()) {
        InquiryObject.tag = INQUIRY_TAG
        InquiryObject.name = INQUIRY
        InquiryObject.status = InquiryObject.status ? InquiryObject.status : OPEN
        InquiryObject.lastUpdated = onDate
        InquiryObject.followUpDate = addDaysToToday(2, onDate);

        InquiryObject.entityPublicAccess = true;
        InquiryObject.effectAccess = userLevels.map(level => level.id);

        const response = await serviceEndpoints.AccountsEndpoint.create(
            token,
            khID,
            InquiryObject
        );

        return response;
    }

    async createProductWithRawMaterialAndProcess(token, khID, InquiryObject, RequirementObject, previousBatches) {
        let deleteVoucherId = InquiryObject.inquiryRequirementVoucherId;

        InquiryObject.inquiryRequirementVoucherId = uuidv4();
        delete InquiryObject.products;

        let object = getRequirementVoucherObject(RequirementObject, InquiryObject, InquiryObject.id, INQUIRY_STORE_ACCOUNT_ID)

        let differentBatcheObjects = differentiateArrays(previousBatches, object.batchObjects)

        for (let i = 0; i < differentBatcheObjects.created.length; i++) {
            await this.createBatche(token, khID, differentBatcheObjects.created[i]);
        }

        for (let i = 0; i < differentBatcheObjects.deleted.length; i++) {
            await this.deleteBatches(token, khID, differentBatcheObjects.deleted[i]);
        }

        const voucher = await this.updateProductVoucher(token, khID, object.voucherObject, deleteVoucherId);

        return await serviceEndpoints.AccountsEndpoint.patch(
            token,
            khID,
            { inquiryRequirementVoucherId: voucher.id },
            InquiryObject.id);
    }

    async getResourceBalance(token, khID, option, pageParam) {
        return await serviceEndpoints.BalanceEndpoint.get(
            token,
            khID,
            null,
            option,
            pageParam
        )
    }

    async updateInquiry(token, khID, InquiryObject, id) {
        return await serviceEndpoints.AccountsEndpoint.put(
            token,
            khID,
            InquiryObject,
            id
        )
    }

    async getInquirySources(token, khID, body, options, pageParam) {
        const inquirySourcesOptions = options ? options : {}
        inquirySourcesOptions.tag = INQUIRY_SOURCE;
        return await serviceEndpoints.AccountsEndpoint.get(token, khID, body, inquirySourcesOptions, pageParam);
    }

    async createInquirySources(token, khID, body) {
        body.tag = INQUIRY_SOURCE;
        body.entityPublicAccess = true;
        return await serviceEndpoints.AccountsEndpoint.create(token, khID, body);
    }

    async deleteInquirySource(token, khID, id) {
        return await serviceEndpoints.AccountsEndpoint.delete(token, khID, id);
    }

    async updateInquirySource(token, khID, state, id) {
        return serviceEndpoints.AccountsEndpoint.put(token, khID, state, id)
    }

    //Files
    async getFile(SessionId, token, khID, options) {
        if (sessionStorage.getItem(SessionId)) {
            let currentSession = JSON.parse(
                sessionStorage.getItem(SessionId)
            );
            return currentSession;
        }
        var data = await serviceEndpoints.FileEndpoint.get(token,
            khID,
            null,
            options
        );
        StoreFileToSession(data, SessionId);
        return {
            ContentType: data[CONTENT_TYPE],
            url: data.url
        };
    }

    async getLeadHistory(token, khID, inquiryID) {
        const options = {
            accountID: inquiryID,
            resourceID: STATE_UPDATE_RESOURCE_ID,
            fromDate: addDaysToToday(-15),
            toDate: new Date().valueOf()
        }

        return await serviceEndpoints.TransactionsEndpoint.getAll(token, khID, null, options)
    }

    async getLabourCostForToday(token, khID, inquiry) {
        const options = {
            accountID: inquiry.id,
            resourceID: INR_RESOURCE_ID,
            type: LABOUR_VOUCHER_TYPE,
            fromDate: inquiry.createdAt,
            toDate: new Date().valueOf()
        }

        return await serviceEndpoints.TransactionsEndpoint.getAll(token, khID, null, options)
    }

    async getExpenseCostForToday(token, khID, inquiry) {
        const options = {
            accountID: inquiry.id,
            resourceID: INR_RESOURCE_ID,
            type: EXTERNAL_VOUCHER_TYPE,
            fromDate: inquiry.createdAt,
            toDate: new Date().valueOf()
        }

        return await serviceEndpoints.TransactionsEndpoint.getAll(token, khID, null, options)
    }

    async getINRStatement(token, khID, inquiry) {
        const options = {
            accountID: inquiry.id,
            resourceID: INR_RESOURCE_ID,
            fromDate: inquiry.createdAt,
            toDate: new Date().valueOf()
        }

        return await serviceEndpoints.TransactionsEndpoint.getAll(token, khID, null, options)
    }

    async getStatement(token, khID, options) {
        return await serviceEndpoints.TransactionsEndpoint.getAll(token, khID, null, options)
    }

    async updateLeadStatus(token, khID, updateProp, inquiryID, note, onDate = new Date(), item) {
        updateProp.lastUpdated = onDate;

        if (item && updateProp.status === CONVERTED) {
            if (!item.customerId) {
                return Promise.reject(createServerErrorMsg("Please Create Or Attach Customer To Inquiry"))
            }

            updateRequiredPropsForOrder(updateProp, item);
        }

        if (updateProp.status === QUOTATION_READY) {
            var nextId = await this.getSeriesNumber(
                token,
                khID,
                {
                    prefix: QUOTATION_SERIES_PREFIX,
                }
            );

            updateProp.quotationId = nextId.id
            updateProp.quotationDate = new Date().getTime();

            const ammendedNote = "New Quotation ID is " + nextId.id
            note = note ? note + " " + ammendedNote : ammendedNote;
        }

        var options = {
            keepRecord: true
        };

        if (updateProp.status === CLOSED) updateProp.tag = CLOSED_INQUIRY_TAG

        if (note) options.transactionNote = note;

        return await serviceEndpoints.AccountsEndpoint.patch(token, khID, updateProp, inquiryID, options);
    }

    async getContacts(token, khID, body, options, pageParam) {
        const contactOptions = options ? options : {}
        contactOptions.tag = CONTACT;
        return await serviceEndpoints.AccountsEndpoint.get(token, khID, body, contactOptions, pageParam)
    }

    async createContact(token, khID, body) {
        body.tag = CONTACT;
        body.effectAccess = [STORE_MANAGER_USER_LEVEL_ID, PRODUCTION_MANAGER_USER_LEVEL_ID];
        return await serviceEndpoints.AccountsEndpoint.create(
            token,
            khID,
            body);
    }

    async creteProductVoucher(token, khID, voucherObject) {
        return await serviceEndpoints.VouchersEndpoint.create(token, khID, voucherObject);
    }

    async patchVoucher(token, khID, voucherObject, id) {
        return await serviceEndpoints.VouchersEndpoint.patch(token, khID, voucherObject, id);
    }

    async updateProductVoucher(token, khID, voucherObject, voucherId) {

        if (voucherId) {
            await serviceEndpoints.VouchersEndpoint.delete(token, khID, voucherId);
        }

        if (voucherObject.transactions?.length === 0) return { id: DELETE_FIELD }

        return await serviceEndpoints.VouchersEndpoint.create(token, khID, voucherObject);
    }

    async getProductWithBatches(token, khID, accountID) {

        let batches = await this.getBatches(token, khID, {
            inquiryId: accountID
        })

        return getProductFiltered(batches)
    }

    async getInquiryProducts(token, khID, inquiryId) {
        let batches = await this.getBatches(token, khID, {
            inquiryId: inquiryId,
            tag: PRODUCT_TAG,
        })
        return batches;
    }

    async createOrder(token, khID, OrderObject, onDate) {
        OrderObject.sourceOfLead = REFERANCE;
        OrderObject.sourceOfLeadId = REFERANCE_ID;
        OrderObject.status = CONVERTED;

        updateRequiredPropsForOrder(OrderObject, OrderObject);
        return await this.createInquiry(token, khID, OrderObject, onDate)
    }

    async updateOrder(token, khID, OrderObject, id) {
        return await this.updateInquiry(token, khID, OrderObject, id)
    }

    //batches
    async createBatche(token, khID, body) {
        return await serviceEndpoints.BatchEndpoint.create(token, khID, body)
    }

    async getBatches(token, khID, options) {
        return await serviceEndpoints.BatchEndpoint.getAll(token, khID, null, options)
    }

    async deleteBatches(token, khID, id) {
        return await serviceEndpoints.BatchEndpoint.delete(token, khID, id)
    }

    async patchBatches(token, khID, id, update) {
        return await serviceEndpoints.BatchEndpoint.patch(token, khID, update, id)
    }

    async getDiscountSlab(token, khID, options = {}) {
        options.tag = DISCOUNT_SLAB_TAG
        return this.getBatches(token, khID, options);
    }

    async createDiscountSlab(token, khID, options = {}) {
        options.tag = DISCOUNT_SLAB_TAG
        return this.createBatche(token, khID, options);
    }

    async deleteDiscountSlab(token, khID, id) {
        return this.deleteBatches(token, khID, id);
    }

    async getMapedBatchBalancePerRawMaterial(token, khID, material, productType) {
        if (productType === PROCESSESS) {
            return Object.entries(material).reduce((acc, [key, value]) => {
                value.debit = value.batches;
                acc[key] = value;
                return acc;
            }, {});
        }

        const promises = Object.keys(material).map(async (key) => {
            return await this.getResourceBalance(
                token,
                khID,
                {
                    resourceID: key,
                    accountID: INQUIRY_STORE_ACCOUNT_ID,
                    date: Date.now(),
                    tag: PRODUCT_TAG
                }
            );
        });

        const stockBalance = await Promise.all(promises);
        // stockBalance has the ramaining balance of rawmaterial into the store
        //where the batches are object with key date and units are remaining units per batch

        return getDistibutedBatches(material, stockBalance.flatMap(item => item))
        // sortAndGiveBaches gives the updated materials.obj with extra key debit batches
        //wich has to be debited from store
        // debited is array of batch with id date and units are reamining required - instore
        // this adjust from next batch
    }

    //allocate Raw Material
    async ReleaseMaterial(token, khID, data, productType = RMLIST) {
        let partialChecking = data.checked
        if (partialChecking) delete data.checked;
        let materials = {}
        if (productType === RMLIST) {
            materials = returnUniqueResource(productType, data.products, partialChecking)
        } else if (productType === PROCESSESS) {
            materials = returnUniqueResourceProcess(productType, data.products)
        }

        const sortAndGiveBaches = await this.getMapedBatchBalancePerRawMaterial(token, khID, materials.obj, productType)
        // sortAndGiveBaches gives the updated materials.obj with extra key debit batches
        //wich has to be debited from store
        // debited is array of batch with id date and units are reamining required - instore
        // this adjust from next batch

        let transactions = []

        for (const [key, value] of Object.entries(sortAndGiveBaches)) {
            transactions.push(
                ...getProductTransactionArray(
                    value,
                    key,
                    INQUIRY_STORE_ACCOUNT_ID,
                    data.id,
                    value.batches,
                    value.debit.map(element => (
                        {
                            ...element,
                            units: element.units * -1
                        }))
                )
            )
        }

        if (productType === PROCESSESS) {
            for (const value of Object.values(data.products)) {
                var debit = [];
                var credit = [];
                var total = 0;
                let units = value.completedFinishedGoods * 1;
                debit.push({ id: data.id, units: units * -1 })
                credit.push({ id: data.id, units: units })
                total += units * 1;
                if (value.completedFinishedGoods) {
                    transactions.push(
                        {
                            resourceID: value.product.id,
                            accountID: data.id,
                            batches: debit,
                            units: total * -1,
                        },
                        {
                            resourceID: value.product.id,
                            accountID: QC_STORE_ACCOUNT_ID,
                            batches: credit,
                            units: total * 1,
                        },
                    )
                }
            }
        }

        if (transactions.length === 0) {
            let errorText = productType === RMLIST ? PRODUCT_TYPE_RAW : PRODUCT_TYPE_PROCESS;
            return Promise.reject(`Server error: 400 Message: Please Do Select or Enter At Least One ${errorText}`);
        }

        let voucherObject = getProductVoucher(
            [],
            data.id,
            undefined,
            transactions,
            true,
        )

        let result = await this.creteProductVoucher(token, khID, voucherObject);
        return { id: result.id, totalDifference: materials.totalDifference }
    }

    async getBalancePerInquiry(token, khID, options) {
        let balanceObject = {}
        let balance = await this.getResourceBalance(token, khID, options)
        balance.forEach(element => {
            Object.entries(element.batches).forEach(([key, value]) => {
                balanceObject[getProductIDResourceID(key, element.resource.id)] = value * 1
            })
        })
        return balanceObject;
    }

    async getBalanceWithResource(token, khID, data, productType = RMLIST) {

        for (let i = 0; i < data.length; i++) {

            let balanceObject = await this.getBalancePerInquiry(token, khID, {
                accountID: data[i].id,
                date: Date.now(),
                tag: PRODUCT_TAG
            })
            addBalancePerInquiryProduct(data[i], balanceObject, productType)

        }
        return data;
    }

    async getInquiryStoreBalance(token, khID, options) {
        let storeBalancePerProduct = await this.getResourceBalance(token, khID, options)
        return getResourceObject(storeBalancePerProduct)
    }

    async getBalancePerInquiryAndStore(token, khID, inquiryOptions) {
        let storeOptions = {
            accountID: INQUIRY_STORE_ACCOUNT_ID,
            date: Date.now(),
            tag: PRODUCT_TAG,
        }
        let storeBalance = await this.getInquiryStoreBalance(token, khID, storeOptions)
        let inquryBalance = await this.getBalancePerInquiry(token, khID, inquiryOptions);
        return { balancePerInquiry: inquryBalance, storeBalancePerProduct: storeBalance };
    }

    async getSeriesNumber(token, khID, options) {
        return await serviceEndpoints.SerializedEndpoint.get(token, khID, null, options)
    }

    async updateCustomer(token, khID, updateProp, id, note, onDate) {
        return await this.updateLeadStatus(token, khID, updateProp, id, note, onDate)
    }

    async createChallan(token, khID, refid, type, products = [], fromAccount, toAccount, date = new Date().getTime(), extraProps) {

        const transaction = []

        for (let i = 0; i < products.length; i++) {

            const item = products[i];

            var creditBatch = [{ id: COMMON_BATCH, units: item.units }];
            var debitBatch = [{ id: COMMON_BATCH, units: item.units * -1 }];

            if (type === STORE_VOUCHER || extraProps?.inquiryId) {
                creditBatch = [{ id: extraProps.inquiryId, units: item.units }];
                debitBatch = [{ id: extraProps.inquiryId, units: item.units * -1 }];
            }

            transaction.push(
                ...getProductTransactionArray(
                    item,
                    item.product.id,
                    fromAccount,
                    toAccount,
                    creditBatch,
                    debitBatch
                )
            );
        }

        const voucher = {
            date: date,
            verified: true,
            refranceId: refid,
            type: type,
            transactions: transaction,
            ...extraProps
        }

        return await this.creteProductVoucher(token, khID, voucher);
    }

    async getChallans(token, khID, body = null, options) {
        const vouchers = await serviceEndpoints.VouchersEndpoint.get(token, khID, body, options);

        for (let i = 0; i < vouchers.length; i++) {
            const transactions = await serviceEndpoints.TransactionsEndpoint.getAll(token, khID, null, { vid: vouchers[i].id });

            const products = transactions
                .filter(trx => trx.units > 0)
                .map(trx => ({ units: Math.abs(trx.units), product: { name: trx.resourceName } }));

            vouchers[i].products = products;
        }

        return vouchers;
    }

    async getInvoices(
        token,
        khID,
        pageParam,
        inquiryID = null,
        customerId = null,
        countOnly = false) {

        const options = {
            type: TAX_INVOICE,
            sort: "createdAt",
            desending: true
        }

        if (customerId) options.customerId = customerId;

        if (inquiryID) options.inquiryId = inquiryID;

        if (countOnly) options.countOnly = true

        return await serviceEndpoints.VouchersEndpoint.get(token, khID, null, options, pageParam);
    }

    async getReceipts(
        token,
        khID,
        pageParam,
        invoiceId = null,
        customerId = null,
        countOnly = false) {

        const options = {
            type: RECEIPT_VOUCHER,
            sort: "createdAt",
            desending: true
        }

        if (customerId) options.customerId = customerId;

        if (invoiceId) options.invoiceId = invoiceId;

        if (countOnly) options.countOnly = true

        return await serviceEndpoints.VouchersEndpoint.get(token, khID, null, options, pageParam);
    }

    async getVoucher(token, khID, id) {
        const voucher = await serviceEndpoints.VouchersEndpoint.getOne(token, khID, id);

        const transactions =
            await serviceEndpoints.TransactionsEndpoint.getAll(
                token, khID, null, { vid: voucher.id });

        voucher.products = transactions
            .filter(trx => trx.accountID === INQUIRY_STORE_ACCOUNT_ID)
            .map(trx => ({
                id: trx.resourceID,
                units: Math.abs(trx.units),
                invoiceRate: trx.invoiceRate,
            }));

        return voucher;
    }

    async deleteChallans(token, khID, voucherId) {
        return await serviceEndpoints.VouchersEndpoint.delete(token, khID, voucherId);
    }

    async deleteAccount(token, khid, id) {
        return await serviceEndpoints.AccountsEndpoint.delete(token, khid, id);
    }

    async createBankAccount(token, khID, bankObject) {
        bankObject.tag = BANK_ACCOUNT_TAG;
        bankObject.enablePayment = true;
        bankObject.inrCapable = true;
        bankObject.effectAccess = [ACCOUNT_USER_LEVEL_ID]
        return await serviceEndpoints.AccountsEndpoint.create(token, khID, bankObject);
    }

    async getBankAccount(token, khID, body, options, pageParam) {
        const bankOptions = options ? options : {};
        bankOptions.tag = BANK_ACCOUNT_TAG;
        return await serviceEndpoints.AccountsEndpoint.get(token, khID, body, bankOptions, pageParam);
    }

    getProductBalanceOfAccount = async (token, khID, acc) => {
        let result = []
        const finishedProductObj = {
            accountID: acc,
            date: Date.now(),
            type: PRODUCT_TYPE_FINISHED,
            tag: PRODUCT_TAG
        }
        let finishedProductBalance = await this.getResourceBalance(
            token,
            khID,
            finishedProductObj
        );
        let customProductBalance = await this.getResourceBalance(
            token,
            khID,
            {
                ...finishedProductObj,
                type: PRODUCT_TYPE_CUSTOM,
            }
        );
        if (finishedProductBalance?.length > 0) result.push(...finishedProductBalance)
        if (customProductBalance?.length > 0) result.push(...customProductBalance)
        return result
    }

    getWhatsAppMessages = async (token, khID, wa_id) => {
        return await serviceEndpoints.MessageEndpoint.getAll(token, khID, null, { wa_id: wa_id })
    }

    sendMessage = async (token, khID, wa_id, text) => {
        const body = {
            wa_id: wa_id,
            text: text,
            channel: "WA"
        }

        return await serviceEndpoints.MessageEndpoint.create(token, khID, body)
    }

    async patchBalance(token, khID, body) {
        return await serviceEndpoints.BalanceEndpoint.patch(token, khID, body, IGNORED_ID);
    }

    async getAllVouchers(token, khID, options) {
        return await serviceEndpoints.VouchersEndpoint.get(token, khID, null, options);
    }

    async createPaymentOrder(token, khID, body) {
        return await serviceEndpoints.PaymentEndpoint.create(token, khID, body)
    }

    async checkVerification(token, khID, body, id) {
        return await serviceEndpoints.PaymentEndpoint.patch(token, khID, body, id);
    }

}