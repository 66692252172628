import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ width: '80%', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <h1 style={{ color: '#0073e6' }}>Privacy Policy</h1>

            <h2 style={{ color: '#0073e6' }}>1. Introduction</h2>
            <p>
                Opankys INC ("Company," "we," "our," "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our Software as a Service (SaaS) Enterprise Resource Planning (ERP) system ("Service"). By using the Service, you consent to the data practices described in this Privacy Policy.
            </p>

            <h2 style={{ color: '#0073e6' }}>2. Information We Collect</h2>
            <p>We may collect the following types of information:</p>
            <ul>
                <li><strong>Personal Information:</strong> Name, email address, phone number, and other contact details.</li>
                <li><strong>Usage Data:</strong> Information about how you use the Service, including IP address, browser type, and operating system.</li>
                <li><strong>Transaction Data:</strong> Details of the subscriptions and transactions you conduct through the Service.</li>
                <li><strong>Cookies and Tracking Technologies:</strong> Information collected through cookies and similar technologies to enhance your experience.</li>
            </ul>

            <h2 style={{ color: '#0073e6' }}>3. How We Use Your Information</h2>
            <p>We use your information for the following purposes:</p>
            <ul>
                <li>To provide and maintain the Service.</li>
                <li>To process transactions and manage your subscription.</li>
                <li>To communicate with you, including sending updates and promotional materials.</li>
                <li>To improve the Service based on user feedback and usage patterns.</li>
                <li>To comply with legal obligations.</li>
            </ul>

            <h2 style={{ color: '#0073e6' }}>4. Sharing Your Information</h2>
            <p>We may share your information with third parties in the following circumstances:</p>
            <ul>
                <li>With service providers who assist us in operating the Service and performing related services.</li>
                <li>With business partners for marketing and promotional purposes, with your consent.</li>
                <li>To comply with legal requirements or protect our legal rights.</li>
                <li>In connection with a merger, acquisition, or sale of all or a portion of our assets.</li>
            </ul>

            <h2 style={{ color: '#0073e6' }}>5. Data Security</h2>
            <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>

            <h2 style={{ color: '#0073e6' }}>6. Your Rights</h2>
            <p>You have the following rights regarding your personal information:</p>
            <ul>
                <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
                <li><strong>Correction:</strong> You can request that we correct or update your personal information.</li>
                <li><strong>Deletion:</strong> You can request that we delete your personal information, subject to certain legal obligations.</li>
                <li><strong>Objection:</strong> You can object to the processing of your personal information in certain circumstances.</li>
                <li><strong>Data Portability:</strong> You can request a copy of your personal information in a structured, machine-readable format.</li>
            </ul>

            <h2 style={{ color: '#0073e6' }}>7. Cookies</h2>
            <p>Our Service uses cookies to enhance your experience. You can manage your cookie preferences through your browser settings. Please note that disabling cookies may affect your ability to use certain features of the Service.</p>

            <h2 style={{ color: '#0073e6' }}>8. Third-Party Links</h2>
            <p>Our Service may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of any third-party sites you visit.</p>

            <h2 style={{ color: '#0073e6' }}>9. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated Privacy Policy on our website. Your continued use of the Service after any such changes constitutes your acceptance of the new Privacy Policy.</p>

            <h2 style={{ color: '#0073e6' }}>10. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at  admin @ opankys.com or you can write us at Opankys INC, 138 Bhawani Peth, Satara - 415002.</p>
        </div>
    );
};

export default PrivacyPolicy;
