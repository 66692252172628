import React from 'react';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { COLOR_TEAL, commonFontSize, commonFontWeight, PRIMARY_COLOR, RECEIPTS, UPDATE_ON_RECEIPTS } from '../../Helpers/ConstantProperties';
import GenericSkeleton from '../GenericComponents/DataView/GenericSkeleton';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Paper, Typography } from '@mui/material';
import { getLocalDateString, ShowNumber } from '../../Helpers/helpers';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CardActionButtons from '../GenericComponents/DataView/CardActionButtons';
import DeleteMenuItem from '../GenericComponents/DataView/DeleteMenuItem';

const ReceiptCard = ({ item }) => {
    const deleteChallan = async (token, khID) => {
        await serviceHelpers.deleteChallans(token, khID, item.id);
    };

    const onDelete = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={"Invoice Deleted."} />,
        navigateTo: RECEIPTS,
        queryFunction: deleteChallan,
        queryKeyValue: UPDATE_ON_RECEIPTS,
    };

    if (item.showSkeleton) return <GenericSkeleton />;

    return (
        <Grid2 key={item.id} xs={12}>
            <Paper
                sx={{
                    borderLeft: `15px solid ${COLOR_TEAL}`,
                    padding: '0.3rem',
                    minWidth: 0,
                    px: 1
                }}
                elevation={2} >

                <Grid2 container alignItems="center" spacing={2}>

                    <Grid2 xs={12} md={2}>
                        <Typography
                            noWrap
                            variant="subtitle2"
                            color={"primary.main"}
                            sx={{
                                fontWeight: commonFontWeight,
                                textAlign: { xs: 'left', sm: 'left' }
                            }}
                        >
                            {item.customerName}
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} md={1}>
                        <Typography
                            noWrap
                            color={PRIMARY_COLOR}
                            fontSize={commonFontSize}
                            fontWeight={commonFontWeight}
                        >
                            {item.id}
                        </Typography>

                    </Grid2>

                    <Grid2 xs={12} md={1}>
                        <Typography
                            noWrap
                            fontSize={commonFontSize}
                        >
                            {getLocalDateString(item.date)}
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} md={5}>
                        <Typography
                            noWrap
                            textAlign={"left"}
                            paddingRight={2}
                            fontSize={commonFontSize}
                        >
                            <ReceiptIcon /> {" " + item.notes}
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} md={2.5}>
                        <Typography
                            noWrap
                            textAlign={"right"}
                            paddingRight={2}
                            fontSize={commonFontSize}
                        >
                            {ShowNumber(item.ammount, 2, true)}
                        </Typography>

                    </Grid2>

                    <Grid2
                        xs={12}
                        md
                        sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                        <CardActionButtons
                            menuList={[
                                <DeleteMenuItem onDelete={onDelete} />
                            ]} />
                    </Grid2>

                </Grid2>

            </Paper>

        </Grid2 >
    );
};

export default ReceiptCard;