import { Paper, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { COLOR_TEAL, COLOR_YELLOW, commonFontSize, commonFontWeight, CONVERTED, GENERATE_INVOICE, INVOICES, NOT_AVAILABLE, PRIMARY_COLOR, PROFORMA_INVOICE_ID, TAX_INVOICE, UPDATE_ON_INQUIRY } from '../../Helpers/ConstantProperties';
import { addDaysToToday, checkValue, getLocalDateString, ShowNumber } from '../../Helpers/helpers';
import CardActionButtons from '../GenericComponents/DataView/CardActionButtons';
import { useNavigate } from 'react-router-dom';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { GenericErrorComponent, GenericSuccessComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import DashboardCardCommonPart from '../Dashboard/DashboardCardCommonPart';
import { INQUIRY_TAG } from '../../Helpers/ExtraProperties';
import GenericSkeleton from '../GenericComponents/DataView/GenericSkeleton';
import ViewMenuItem from '../GenericComponents/DataView/ViewMenuItem';
import DeleteMenuItem from '../GenericComponents/DataView/DeleteMenuItem';
import AddTransport from '../Dashboard/DashboardMenuOptions/AddTransport';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';

const InvoiceCard = ({ item }) => {

    const deleteChallan = async (token, khID) => {
        await serviceHelpers.deleteChallans(token, khID, item.id);

        const update = {
            followUpDate: addDaysToToday(2),
            status: CONVERTED,
            tag: INQUIRY_TAG,
            readyToDispatch: true,
        }

        const note = "Invoice " + item.id + " deleted, items returned to store."

        return await serviceHelpers.updateLeadStatus(token, khID, update, item.inquiryId, note);
    };

    const navigate = useNavigate();

    const onDelete = {
        ErrorComponent: ({ error }) => <GenericErrorComponent error={error} />,
        SuccessComponent: ({ data }) => <GenericSuccessComponent data={data} message={"Invoice Deleted."} />,
        navigateTo: INVOICES,
        queryFunction: deleteChallan,
        queryKeyValue: UPDATE_ON_INQUIRY,
    };

    if (item.showSkeleton) return <GenericSkeleton />;

    if (item.id === PROFORMA_INVOICE_ID) {
        return (
            <Grid2 key={item.id} xs={12}>
                <Paper
                    sx={{
                        borderLeft: `15px solid ${COLOR_YELLOW}`,
                        padding: '0.3rem',
                        minWidth: 0,
                        px: 1
                    }}
                    elevation={2} >

                    <Grid2 container alignItems="center" spacing={2}>
                        <Grid2 xs={12} md={1}>
                            <Typography
                                noWrap
                                color={PRIMARY_COLOR}
                                fontSize={commonFontSize}
                                fontWeight={commonFontWeight}
                            >
                                {item.id}
                            </Typography>

                        </Grid2>

                        <Grid2 xs={12} md>
                        </Grid2>

                        <Grid2
                            xs={12}
                            md
                            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                            <CardActionButtons
                                menuList={[<ViewMenuItem onView={() => {
                                    navigate(
                                        GENERATE_INVOICE,
                                        { state: { voucherObject: item, inquiryObject: item.inquiry } })
                                }} />
                                ]} />
                        </Grid2>

                    </Grid2>
                </Paper>
            </Grid2>
        )
    }

    const roundedTotal = item.cData ? item.cData.roundedTotal : item.roundedTotal;
    const customerData = item.cData ? { ...item.cData, type: TAX_INVOICE } : item;

    return (
        <Grid2 key={item.id} xs={12}>
            <Paper
                sx={{
                    borderLeft: `15px solid ${COLOR_TEAL}`,
                    padding: '0.3rem',
                    minWidth: 0,
                    px: 1
                }}
                elevation={2} >

                <Grid2 container alignItems="center" spacing={2}>
                    <DashboardCardCommonPart item={customerData} />

                    <Grid2 xs={12} md={1}>
                        <Typography
                            noWrap
                            color={PRIMARY_COLOR}
                            fontSize={commonFontSize}
                            fontWeight={commonFontWeight}
                        >
                            {item.id}
                        </Typography>

                    </Grid2>

                    <Grid2 xs={12} md={1}>
                        <Typography
                            noWrap
                            fontSize={commonFontSize}
                        >
                            {getLocalDateString(item.date)}
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} md={1.5}>
                        <Typography
                            noWrap
                            fontSize={commonFontSize}
                        >
                            <LocalShippingIcon />{" "}
                            {item.vehicalNo}
                        </Typography>

                    </Grid2>

                    <Grid2 xs={12} md={1.5}>
                        <Typography
                            noWrap
                            fontSize={commonFontSize}
                        >
                            <ReceiptIcon /> {" "}
                            {
                                item.transportConst
                                    ? ShowNumber(item.transportConst, 2, true)
                                    : NOT_AVAILABLE
                            }
                        </Typography>

                    </Grid2>

                    <Grid2 xs={12} md={1.5}>
                        <Typography
                            noWrap
                            textAlign={"right"}
                            paddingRight={2}
                            fontSize={commonFontSize}
                        >
                            PO : {checkValue(item.inquiry?.poNumber)}
                        </Typography>
                    </Grid2>

                    <Grid2 xs={12} md={1.5}>
                        <Typography
                            noWrap
                            textAlign={"right"}
                            paddingRight={2}
                            fontSize={commonFontSize}
                        >
                            {
                                roundedTotal
                                    ? ShowNumber(roundedTotal, 2, true)
                                    : NOT_AVAILABLE
                            }
                        </Typography>

                    </Grid2>

                    <Grid2
                        xs={12}
                        md
                        sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                        <CardActionButtons
                            menuList={[
                                <AddTransport item={item} />,
                                <ViewMenuItem onView={() => {
                                    navigate(
                                        GENERATE_INVOICE,
                                        { state: { voucherID: item.id, inquiryObject: item.inquiry } })
                                }} />,
                                <DeleteMenuItem onDelete={onDelete} />
                            ]} />
                    </Grid2>

                </Grid2>

            </Paper>

        </Grid2 >
    );
};

export default InvoiceCard;