import React, { useContext } from 'react';
import INRStatementDoc from './INRStatementDoc';
import { AuthContext } from '../Auth/Auth';
import { FirmContext } from '../Contexts/FirmContext';
import { STATEMENT, UPDATE_ON_PAYMENT, UPDATE_ON_RECEIPTS, UPDATE_ON_VOUCHER } from '../../Helpers/ConstantProperties';
import { serviceHelpers } from '../../Helpers/ServiceHelpers';
import { useQuery } from '@tanstack/react-query';
import GenericSpinner from '../GenericComponents/FormComponent/GenericSpinner';
import { GenericErrorComponent } from '../GenericComponents/FormComponent/GenericAlertComponent';
import { getStatementData } from '../Quotation/RateCalculator';
import StatementTable from './StatementTable';

const StatementData = ({ state }) => {
    const { token } = useContext(AuthContext);
    const { khID } = useContext(FirmContext);

    const queryKey = [
        token,
        khID,
        UPDATE_ON_RECEIPTS,
        UPDATE_ON_PAYMENT,
        UPDATE_ON_VOUCHER,
        state.resource.id,
        STATEMENT + state.account.id
    ];

    const queryFunction = async () => {

        const options = {
            accountID: state.account.id,
            resourceID: state.resource.id,
            fromDate: state.fromDate,
            toDate: state.toDate
        }

        const results = await serviceHelpers.getStatement(token, khID, options);
        return results;
    }

    const { data, isLoading, error } = useQuery({
        queryKey: queryKey,
        queryFn: queryFunction
    })

    if (isLoading) return <GenericSpinner />

    if (error) return <GenericErrorComponent error={error} />

    if (data) {
        const result = getStatementData({ transactions: data });

        result.state = state;

        if (state.showPDF)
            return <INRStatementDoc result={result} />

        return <StatementTable result={result} />
    }

    return <></>
};

export default StatementData;