import React from 'react';

const RefundPolicy = () => {
    return (
        <div style={{ width: '80%', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <h1 style={{ color: '#0073e6' }}>Refund Policy</h1>

            <h2 style={{ color: '#0073e6' }}>1. No Refunds</h2>
            <p>All sales are final. Once a purchase is completed, it is non-refundable. We do not offer refunds for any reason, including but not limited to dissatisfaction with the Service, accidental purchases, or changes in your needs or circumstances.</p>

            <h2 style={{ color: '#0073e6' }}>2. Subscription Cancellations</h2>
            <p>If you wish to cancel your subscription, you may do so at any time. However, please note that canceling your subscription will not result in a refund of any fees already paid. You will continue to have access to the Service until the end of your current subscription period.</p>

            <h2 style={{ color: '#0073e6' }}>3. Chargebacks</h2>
            <p>Attempting to initiate a chargeback or dispute with your credit card company or payment provider is a violation of this Refund Policy. We reserve the right to take legal action against any individual or entity that attempts to circumvent this policy.</p>

            <h2 style={{ color: '#0073e6' }}>4. Payment Information</h2>
            <p>We do not store any payment information on our end. All payment processing is handled securely by third-party payment providers. Your payment details are protected by the security measures implemented by these providers.</p>

            <h2 style={{ color: '#0073e6' }}>5. Contact Us</h2>
            <p>If you have any questions about this Refund Policy, please contact us at admin @ opanys.com or you can write us at Opankys INC, 138 Bhawani Peth, Satara - 415002. We are committed to providing you with the best possible service and support.</p>
        </div>
    );
};

export default RefundPolicy;
