import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { getLocalDateString } from '../../../Helpers/helpers';

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        marginBottom: 5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "Roboto",
    },
    text: {
        fontSize: 9,
    },
});

const StmtClosingBalance = ({ context }) => {
    const runningText = context.cData.runningText;
    const textColor = context.cData.textColor;
    const toDate = context.cData.toDate;

    const Colortext = {
        fontSize: 10,
        color: textColor
    }

    return (
        <View>
            <View style={styles.row}>
                <Text style={[styles.text, Colortext]}>Closing Balance:</Text>
                <Text style={[styles.text, Colortext]}>
                    {runningText}
                </Text>
            </View>

            <View style={styles.row}>
                <Text style={styles.text}>As On:</Text>
                <Text style={styles.text}>{getLocalDateString(toDate)}</Text>
            </View>
        </View>
    );
};

export default StmtClosingBalance;