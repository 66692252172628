import React from 'react';
import BankComponent from './BankComponent';

const StmtBankComponent = ({ context, currentFirm }) => {
    if (context.showBank)
        return <BankComponent context={context} currentFirm={currentFirm} />

    return <></>
};

export default StmtBankComponent;