import { Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import { getLocalDateString, ShowNumber } from '../../../Helpers/helpers';
import StmtTxnArrow from "./StmtTxnArrow"

const styles = StyleSheet.create({
    table: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#e0e0e0",
        borderRadius: 4,
        fontFamily: "Roboto",
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#e0e0e0",
        borderBottomStyle: "solid",
        alignItems: "center",
    },

    tableBodyRow: {
        paddingVertical: "1mm"
    },

    tableHeader: {
        backgroundColor: "#cfe2ff",
        fontWeight: "bold",
        textAlign: "center",
        paddingVertical: "1mm",
        fontSize: 7,
    },
    tableCell: {
        fontSize: 7,
        textAlign: "center",
    },

    detailsCell: {
        paddingLeft: "1mm",
        fontSize: 7,
        textAlign: "left",
    },
    taxRow: {
        paddingVertical: "0.5mm",
    },
    amountCell: {
        textAlign: "right",
        paddingRight: "1mm",
    },
    subText: {
        fontSize: 7,
        marginTop: 2,
    },
    rightBorder: {
        borderRightWidth: 1,
        borderRightColor: "#e0e0e0",
        borderRightStyle: "solid",
    },
});

const StmtTransactions = ({ context }) => {
    const statements = context.cData.statements;

    return (
        <View style={styles.table}>

            {/* Table Header */}
            <View style={styles.tableRow}>

                <View
                    style={[
                        styles.tableCell,
                        styles.tableHeader,
                        { flexBasis: (5.5 / 12 * 100) + "%" },
                    ]}
                >
                    <Text>Details</Text>
                </View>

                <View
                    style={[
                        styles.tableCell,
                        styles.tableHeader,
                        { flexBasis: (1.5 / 12 * 100) + "%" },
                    ]}
                >
                    <Text>Date</Text>
                </View>

                <View
                    style={[
                        styles.tableCell,
                        styles.tableHeader,
                        { flexBasis: (2 / 12 * 100) + "%" },
                    ]}
                >
                    <Text>Document ID</Text>
                </View>

                <View
                    style={[
                        styles.tableCell,
                        styles.tableHeader,
                        { flexBasis: (3 / 12 * 100) + "%" },
                    ]}
                >
                    <Text>Amount</Text>
                </View>

            </View>

            {/* Table Body */}
            {
                statements.map((txn) => {
                    const textColor = { color: txn.textColor };
                    return (
                        <View key={txn.id} style={[styles.tableRow, styles.tableBodyRow]}>

                            <View style={[
                                styles.detailsCell,
                                styles.rightBorder,
                                { flexBasis: (5.5 / 12 * 100) + "%" }
                            ]}>

                                <Text>{txn.type}</Text>
                                <Text style={styles.subText}>{txn.notes}</Text>

                            </View>

                            <View style={[
                                styles.tableCell,
                                styles.rightBorder,
                                { flexBasis: (1.5 / 12 * 100) + "%" }
                            ]}>
                                <Text>{getLocalDateString(txn.date)}</Text>
                            </View>

                            <View style={[
                                styles.tableCell,
                                styles.rightBorder,
                                { flexBasis: (2 / 12 * 100) + "%" }
                            ]}>
                                <Text>{txn.vid}</Text>
                            </View>

                            <View style={[
                                styles.tableCell,
                                styles.amountCell,
                                { flexBasis: (2.5 / 12 * 100) + "%" }
                            ]}>
                                <Text>{ShowNumber((txn.credit || txn.debit), 2, true)}</Text>
                                <Text style={[styles.subText, textColor]}>
                                    Balance {txn.runningText}
                                </Text>
                            </View>

                            <View style={[
                                styles.tableCell,
                                styles.amountCell,
                                { flexBasis: (0.5 / 12 * 100) + "%" }
                            ]}>
                                <StmtTxnArrow txn={txn} />
                            </View>

                        </View>
                    )
                })
            }

        </View>
    );
};

export default StmtTransactions;