import React from 'react';

const Terms = () => {
    return (
        <div style={{ width: '80%', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <h1 style={{ color: '#0073e6' }}>Terms and Conditions</h1>

            <h2 style={{ color: '#0073e6' }}>1. Introduction</h2>
            <p>Welcome to Opankys INC ("Company," "we," "our," "us"). These Terms and Conditions ("Terms") govern your use of our Software as a Service (SaaS) Enterprise Resource Planning (ERP) system ("Service"). By accessing or using the Service, you agree to be bound by these Terms.</p>

            <h2 style={{ color: '#0073e6' }}>2. User Accounts</h2>
            <p>To use our Service, you must create an account. You agree to provide accurate and complete information when creating an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

            <h2 style={{ color: '#0073e6' }}>3. Subscription and Payment</h2>
            <p>Access to the Service is provided on a subscription basis. You agree to pay all applicable fees as outlined on our pricing page. All payments are non-refundable except as provided in these Terms or as required by law.</p>

            <h2 style={{ color: '#0073e6' }}>4. License</h2>
            <p>We grant you a limited, non-exclusive, non-transferable license to use the Service for your internal business purposes. You agree not to sublicense, distribute, or modify the Service.</p>

            <h2 style={{ color: '#0073e6' }}>5. User Responsibilities</h2>
            <p>You agree to use the Service in compliance with all applicable laws and regulations. You must not:</p>
            <ul>
                <li>Engage in unauthorized access, use, or disclosure of our Service.</li>
                <li>Use the Service for any illegal activities.</li>
                <li>Interfere with the proper functioning of the Service.</li>
            </ul>

            <h2 style={{ color: '#0073e6' }}>6. Intellectual Property</h2>
            <p>All intellectual property rights in the Service are owned by us or our licensors. You acknowledge that you have no rights to the Service other than the license granted under these Terms.</p>

            <h2 style={{ color: '#0073e6' }}>7. Confidentiality</h2>
            <p>You agree to maintain the confidentiality of any non-public information that we disclose to you in connection with the Service. This obligation will continue even after you stop using the Service.</p>

            <h2 style={{ color: '#0073e6' }}>8. Data Privacy</h2>
            <p>We are committed to protecting your privacy. Our Privacy Policy outlines how we collect, use, and safeguard your personal information. By using the Service, you consent to our data practices as described in the Privacy Policy.</p>

            <h2 style={{ color: '#0073e6' }}>9. Disclaimer of Warranties</h2>
            <p>The Service is provided "as is" and "as available," without any warranties of any kind, either express or implied. We disclaim all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

            <h2 style={{ color: '#0073e6' }}>10. Limitation of Liability</h2>
            <p>To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or use, arising out of or in connection with the Service.</p>

            <h2 style={{ color: '#0073e6' }}>11. Indemnification</h2>
            <p>You agree to indemnify and hold us harmless from any claims, liabilities, damages, losses, or expenses arising out of your use of the Service or violation of these Terms.</p>

            <h2 style={{ color: '#0073e6' }}>12. Modifications</h2>
            <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the updated Terms on our website. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.</p>

            <h2 style={{ color: '#0073e6' }}>13. Termination</h2>
            <p>We may terminate or suspend your access to the Service at any time, with or without cause, and without prior notice. Upon termination, your right to use the Service will immediately cease.</p>

            <h2 style={{ color: '#0073e6' }}>14. Governing Law</h2>
            <p>These Terms are governed by and construed in accordance with the laws of Satara Jurisdiction, without regard to its conflict of law principles. Any disputes arising under these Terms shall be resolved in the courts of Satara Jurisdiction.</p>

            <h2 style={{ color: '#0073e6' }}>15. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at admin @ opankys.com or you can write us at Opankys INC, 138 Bhawani Peth, Satara - 415002.</p>
        </div>
    );
};

export default Terms;
