import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
    },
    text: {
        fontSize: 8,
        fontFamily: "Roboto",
    },
});

const EndOfStatement = ({ context, page }) => {
    const cData = context.cData;
    return (
        <View style={styles.row}>
            <Text style={styles.text}>This is computer generated Statement, it does not required signature.</Text>
            <Text style={styles.text}>Page {page + 1} of {cData.totalPages} </Text>
        </View>
    )
};

export default EndOfStatement;