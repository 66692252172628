import { useLocation } from 'react-router-dom';
import StatementData from './StatementData';

const StatementState = () => {

    const location = useLocation();
    const state = location.state;

    return <StatementData state={state} />
};

export default StatementState;